var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission)?_c('v-card',{attrs:{"max-width":"1920","flat":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("views.settings.user_management"))+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[_c('v-btn',{attrs:{"elevation":"0","disabled":_vm.isLoading},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"elevation":"0","color":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.routeToUserCreation()}}},[_vm._v(" "+_vm._s(_vm.$t("views.user-management.new"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-account-plus")])],1)],1)]),_c('v-card-title',{staticClass:"d-flex pt-0",staticStyle:{"gap":"0.5rem"}},[_c('v-text-field',{ref:"searchField",staticStyle:{"max-width":"300px"},attrs:{"value":_vm.searchValue,"disabled":_vm.isLoading || _vm.fulltextSearchDisabled,"label":_vm.$t('views.user-management.search_label'),"prepend-inner-icon":"mdi-magnify","dense":"","outlined":"","hide-details":"","clearable":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.startFulltextSearch()},"click:clear":function($event){return _vm.clearFulltextSearch()}}}),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.fulltextSearchDisabled,"loading":_vm.isLoading},on:{"click":function($event){return _vm.startFulltextSearch()}}},[_vm._v(" "+_vm._s(_vm.$t("views.user-management.search"))+" ")])],1),_vm._l((_vm.filterStates.filter(function (ref) {
        var type = ref.type;

        return type === 'list';
})),function(filter){return _c('div',{key:filter.field,staticClass:"d-flex mx-4",class:_vm.$vuetify.breakpoint.xsOnly ? 'flex-column align-start' : 'align-center',style:(_vm.$vuetify.breakpoint.xsOnly ? 'gap: 0rem' : 'gap: 0.5rem')},[_c('span',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(_vm._s(filter.label))]),_c('v-chip-group',{attrs:{"value":_vm.chipGroupValue,"multiple":"","column":"","active-class":"primary--text"},on:{"change":function($event){_vm.updateFilter({
          field: filter.field,
          condition: 'IN',
          value: $event.map(function (index) { return filter.items[index].value; }),
        });
        _vm.reload();}}},_vm._l((filter.items),function(filterItem){return _c('v-chip',{key:filterItem.value,attrs:{"outlined":"","disabled":_vm.groupFilterDisabled}},[_vm._v(" "+_vm._s(filterItem.text)+" ")])}),1)],1)}),_c('v-card-text',[(_vm.error)?_c('v-alert',{attrs:{"type":"error","outlined":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t("views.user-management.user-loading-error", { status: _vm.error.status, }))+" ")]):_vm._e(),_c('v-data-table',{attrs:{"id":"paginationstart","headers":_vm.headers,"items":_vm.usersForDisplay,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":1,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.showUserDetails(item)}}},[_c('td',{staticClass:"cell-displayName"},[_vm._v(" "+_vm._s(item.displayName)+" ")]),_c('td',{staticClass:"cell-email"},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',{staticClass:"cell-roles"},[_c('span',{attrs:{"title":item.displayRoles}},[_vm._v(" "+_vm._s(item.displayRoles)+" ")])]),_c('td',{staticClass:"cell-groups"},[_c('span',{attrs:{"title":item.displayRoles}},[_vm._v(" "+_vm._s(item.displayGroups)+" ")])]),_c('td',{staticClass:"cell-id"},[_c('div',{staticClass:"id",attrs:{"title":item.id}},[_vm._v(" "+_vm._s(item.id)+" "),_c('v-btn',{staticClass:"copy-button text-body-2 no-uppercase px-0 mx-0",attrs:{"text":"","x-small":"","icon":"","title":_vm.$t('views.user-management.copy_to_clipboard')},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)],1)]),_c('td',{staticClass:"cell-type"},[_vm._v(" "+_vm._s(item.type)+" ")]),_c('td',{staticClass:"cell-active"},[(item.active)?_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e()],1)])]}}],null,false,3277855701)})],1),_c('v-card-actions',[_c('v-spacer'),(_vm.hasMore)?_c('v-btn',{staticClass:"px-4",attrs:{"elevation":"0","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("views.user-management.user-load-more"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-down")])],1):_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t("views.user-management.user-no-more-results"))+" ")]),_c('v-spacer')],1)],2):_c('div',{staticClass:"text-center py-16 my-16"},[_vm._v(" "+_vm._s(_vm.$t("views.template_matrix.missing_permissions"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }