






































































































































































import { ApiV0UserTypesUser as ApiUser } from "@auditcloud/shared/lib/schemas";

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { api as appApi } from "@/store/modules/app";
import { api as userGroupsApi } from "@/store/modules/userGroups";
import { api as userManagementApi } from "@/store/modules/userManagement";
import { ROUTE_NAMES } from "@/routenames";
import {
  TodoActionSignature,
  typeIsSomething,
} from "@auditcloud/shared/lib/utils/type-guards";
import {
  ApiError,
  ApiFilterItem,
  ApiOrderByItem,
  ApiUserParams,
} from "@/store/modules/userManagement/types";
import { zip } from "lodash";
import { CollectionObserverConfig } from "@/utils/firestore";
import { copyToClipboard } from "@auditcloud/shared/lib/utils";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";

type FilterState = {
  field: string;
  label: string;
  disabled: boolean;
};
type TextFilterState = FilterState & {
  type: "text";
  value: string;
};
type ListFilterState = FilterState & {
  type: "list";
  value: string[];
  items: { value: string; text: string }[];
};

type VDataTableOptions = {
  sortBy: string[];
  sortDesc: boolean[];
};

// TODO ACS-1337 import from common
type Group = { id: string; name: string; active: boolean };

@Component({
  components: {},
})
export default class AUsers extends Vue {
  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  @Action(userManagementApi.actions.reload, {
    namespace: userManagementApi.namespace,
  })
  reload!: () => Promise<void>;

  @Action(userManagementApi.actions.loadMore, {
    namespace: userManagementApi.namespace,
  })
  loadMore!: () => Promise<void>;

  @Action(userManagementApi.actions.updateFilters, {
    namespace: userManagementApi.namespace,
  })
  updateFilters!: (whereItem: ApiFilterItem[]) => Promise<void>;

  @Action(userManagementApi.actions.updateOrderBy, {
    namespace: userManagementApi.namespace,
  })
  updateOrderBy!: (orderBy: ApiOrderByItem[]) => Promise<void>;

  @Action(userGroupsApi.actions.listenOnCollection, {
    namespace: userGroupsApi.namespace,
  })
  listenForGroups!: (config: CollectionObserverConfig) => void;

  @Action(userGroupsApi.actions.clearDocuments, {
    namespace: userGroupsApi.namespace,
  })
  unlistenGroups!: (config: CollectionObserverConfig) => void;

  copy(text: string) {
    copyToClipboard(text);
  }

  get hasPermission() {
    return (
      this.$user.roles().includes(SystemRoles.ADMINISTRATOR) ||
      this.$user.roles().includes(SystemRoles.SYSTEM_ADMINISTRATOR)
    );
  }

  /** v-chip-group values is an array of indexes */
  get chipGroupValue() {
    const filters = this.params.where;
    const groupFilter = filters.find(val => val.field === "groups");

    if (!groupFilter) {
      return [];
    }

    return this.groups
      .map(({ id }, index) => (groupFilter.value.includes(id) ? index : null))
      .filter(typeIsSomething);
  }

  updateFilter({ field, condition, value }) {
    const newFilters = this.params.where.filter(
      existingFilter => existingFilter.field !== field
    );

    if (typeof value === "string" && value !== "") {
      newFilters.push({ field, condition, value });
    }

    if (Array.isArray(value) && value.length) {
      newFilters.push({ field, condition, value: value.join(",") });
    }

    return this.updateFilters(newFilters);
  }

  applyFilters() {
    this.reload();
    this.showFilters = false;
  }

  clearFilters() {
    this.updateFilters([]).then(() => {
      return this.reload();
    });
    this.showFilters = false;
  }

  get searchValue(): string {
    return (
      this.params.where
        .filter(({ field }) => field === "search.prefixes")
        .map(({ value }) => value)
        .pop() ?? ""
    );
  }

  showFilters: boolean = false;

  options: VDataTableOptions = {
    sortBy: ["displayName"],
    sortDesc: [false],
  };

  @Watch("options", { deep: true, immediate: true })
  handleUpdateOptions() {
    const { sortBy = [], sortDesc = [] } = this.options;
    const desc = sortDesc.map(desc => (desc ? "DESC" : ""));
    const orderBy: ApiOrderByItem[] = zip(sortBy, desc).map(
      ([field = "", desc]) => ({
        field,
        direction: desc ? "DESC" : "ASC",
      })
    );
    this.updateOrderBy(orderBy);
    this.reload();
  }

  @Getter(userManagementApi.getters.hasMore, {
    namespace: userManagementApi.namespace,
  })
  hasMore!: boolean;

  @Getter(userManagementApi.getters.isLoading, {
    namespace: userManagementApi.namespace,
  })
  isLoading!: boolean;

  @Getter(userManagementApi.getters.getUserList, {
    namespace: userManagementApi.namespace,
  })
  users!: ApiUser[];

  @Getter(userManagementApi.getters.getError, {
    namespace: userManagementApi.namespace,
  })
  error!: ApiError | null;

  @Getter(userManagementApi.getters.getParams, {
    namespace: userManagementApi.namespace,
  })
  params!: ApiUserParams;

  @Getter(userGroupsApi.getters.getGroupList, {
    namespace: userGroupsApi.namespace,
  })
  groups!: Group[];

  get fulltextSearchDisabled() {
    return this.chipGroupValue.length > 0;
  }
  get groupFilterDisabled() {
    return this.searchValue.trim() !== "";
  }

  startFulltextSearch() {
    const input = (this.$refs.searchField as Vue).$el.querySelector("input");
    const searchValue = input?.value.trim() ?? "";
    const value = searchValue.length > 0 ? searchValue : null;
    this.updateFilter({
      field: "search.prefixes",
      condition: "EQ",
      value,
    }).then(() => {
      return this.reload();
    });
  }

  clearFulltextSearch() {
    this.updateFilter({
      field: "search.prefixes",
      condition: "EQ",
      value: null,
    }).then(() => {
      return this.reload();
    });
  }

  get currentBreadCrumb(): any[] {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.settings.breadcrumb"),
        to: { name: ROUTE_NAMES.SETTINGS },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.user-title"),
        to: { name: ROUTE_NAMES.USER_MANAGEMENT_USERS },
        activeclass: "",
      },
    ];
  }

  get usersForDisplay(): (ApiUser & {
    displayRoles: string;
    displayGroups: string;
  })[] {
    return this.users.map(user => {
      const displayGroups = this.groups
        .filter(group => {
          return [
            ...(user.groups ?? []),
            ...(user.applicationGroups ?? []),
          ].includes(group.id);
        })
        .map(group => group.name)
        .join(", ");

      const displayRoles = user.roles
        .map(role => {
          const messageId = `views.user.roles_${role}`;
          return this.$te(messageId) ? `${this.$t(messageId)}` : role;
        })
        .join(", ");

      return { ...user, displayGroups, displayRoles };
    });
  }

  get filterStates(): (TextFilterState | ListFilterState)[] {
    const currentFilter = this.params.where[0] ?? null;
    return this.headers
      .filter(({ filterable }) => filterable)
      .map(header => {
        const { value: field = "", text: label } = header;
        const type = header.type === "list" ? "list" : "text";
        const disabled = currentFilter && currentFilter.field !== field;
        const [paramValue = null] = this.params.where
          .filter(whereItem => whereItem.field === field)
          .map(whereItem => whereItem.value);
        if (type === "list") {
          return {
            type,
            field,
            disabled,
            label,
            value: paramValue ? paramValue.split(",") : [],
            items: header.items ?? [],
          };
        } else {
          const exactHint = this.$t(
            "views.user-management.user-filters-exact-match"
          );
          return {
            type,
            field,
            disabled,
            label: `${label} (${exactHint})`,
            value: paramValue ?? "",
          };
        }
      });
  }

  get headers() {
    return [
      {
        type: "text",
        text: this.$t("views.user.fullname_lb").toString(),
        align: "start",
        sortable: true,
        filterable: true,
        value: "displayName",
      },
      {
        type: "text",
        text: this.$t("views.user.email_lb").toString(),
        align: "start",
        sortable: true,
        filterable: true,
        value: "email",
      },
      {
        type: "list",
        text: this.$t("views.user.roles_lb").toString(),
        align: "start",
        sortable: false,
        value: "roles",
      },
      {
        type: "list",
        text: this.$t("views.user.groups_lb").toString(),
        align: "start",
        sortable: false,
        filterable: true,
        value: "groups",
        items: this.groups
          .filter(({ active }) => active)
          .map(group => ({
            text: group.name,
            value: group.id,
          })),
      },
      {
        type: "text",
        text: this.$t("views.user.id_lb").toString(),
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        type: "text",
        text: this.$t("views.user.type_lb").toString(),
        align: "start",
        sortable: false,
        value: "type",
      },
      {
        type: "text",
        text: this.$t("views.user.active_lb").toString(),
        align: "start",
        sortable: false,
        value: "active",
      },
    ];
  }

  showUserDetails(user: ApiUser) {
    this.$router.push({
      name: ROUTE_NAMES.USER_MANAGEMENT_USER_DETAILS,
      params: {
        userId: user.id!,
      },
    });
  }

  mounted() {
    this.setBreadCrumb(this.currentBreadCrumb);
    this.setMenuPanel();
    this.reload();
    this.listenForGroups({});
  }
  unmounted() {
    this.unlistenGroups({});
  }

  routeToUserCreation() {
    this.$router.push({ name: ROUTE_NAMES.USER_MANAGEMENT_CREATE_USERS });
  }
}
